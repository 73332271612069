import React, {useCallback, useEffect, useRef, useState} from 'react'
import ModalWindow from "../ModalWindow";
import {createRules, updateRules} from "../../../actions/requests";
import {useDispatch, useSelector} from "react-redux";
import TagInput from "../TagInput";
import '../ReplyForm/ReplyForm.scss'
import AdditionalModals from "./AdditionalModals";
import ActionBlock from "./ActionBlock";
import TriggersBlock from "./TriggersBlock";

const RulesModal = ({rule, handleClose}) => {
    const dispatch = useDispatch()
    const ruleForm = useRef()
    const btnRef = useRef()
    const privateRef = useRef()

    const msgRef0 = useRef()
    const msgRef1 = useRef()
    const msgRef2 = useRef()

    const handleRuleForm = (e) => {
        e.preventDefault()
        const arr = [0,1,2]
        arr.map((x) => {
            eval(`msgRef${x}`).current?.DOM?.input?.focus()
        })
        const formData = new FormData(ruleForm.current)
        privateRef.current && formData.append("rule[private_reply]", privateRef.current.checked)
        if(rule){
            dispatch(updateRules(rule.id, formData))
        }else{
            dispatch(createRules(formData))
        }
        handleClose()
    }


    const isValidForm = useCallback(e => {
        let valid = false
        const formData = new FormData(ruleForm.current)
        for(let pair of formData.entries()) {
            console.log('pair', pair)
            if(!pair[1] && pair[0] != "rule[reply_variants][]" && pair[0] != "rule[reply_text]") {
                console.log('p true air', pair)
                valid = true
            }
        }

        if(msgRef1.current){
            const text0 = msgRef0.current?.DOM?.input?.innerText
            const text1 = msgRef1.current?.DOM?.input?.innerText || ""
            const text2 = msgRef2.current?.DOM?.input?.innerText || ""
            if (text0 === text1 && text1 === text2  && text2 === ""){
                valid = true
            }
        }
        
        btnRef.current.disabled = valid
    }, [])

    return (
        <ModalWindow hideClose={true}
                     header={`${rule ? 'Edit' : 'Add new'} automation`} className="giveFeedbackModal"
                     closeHandler={() => handleClose()}
        >
            <form onSubmit={handleRuleForm} ref={ruleForm}>

                <div className="row rule-row">
                    <div className="form-group mb-2">
                        <label>Name</label>
                        <input type="text" name="rule[name]"
                               defaultValue={rule && rule.attributes.name}
                               className="form-control"
                               required="required"
                               onChange={isValidForm}/>
                    </div>

                    <TriggersBlock rule={rule} isValidForm={isValidForm} />

                    <ActionBlock rule={rule} msgRef={[msgRef0, msgRef1, msgRef2]} privateRef={privateRef} isValidForm={isValidForm}/>
                </div>

                <div className="row mt-4">
                    <div>
                        <button type="submit" className="btn btn-primary float-end" ref={btnRef} disabled={true}>Save</button>
                        <button type="reset" className="btn btn-secondary float-end mx-2"
                                onClick={() => handleClose()}>Cancel</button>
                    </div>
                </div>
            </form>

        </ModalWindow>
    )
}

export default React.memo(RulesModal)